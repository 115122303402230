.footer-item
	+content-margins
		margin-top: r(20px)
	ul
		@extend .body-3
		+content-margins
			margin-top: r(16px)
		li
			display: flex
			span
				margin-right: r(12px)
				position: relative
				top: r(4px)
				+text(blue-500)
				flex: 0 0 r(18px)
			a
				+on-hover
					+text(red-500)

.footer-copyright
	padding: r(20px) 0
	border-top: 1px solid color(secondary-300)
	@extend .body-4
	text-align: center
	+mq-min(lg)
		display: flex
		align-items: center
		justify-content: space-between

.footer-contact-form
	.row
		+gap-override(12px)
	.form-group
		& + .form-group
			margin-top: r(12px)