.product-item
	border: 1px solid color(gray-100)
	.product-img
		figure
			a
				display: block
				+res-ratio(682, 382)
				img
					object-fit: contain
					width: 100%
					height: auto
					+center(both)
	.product-title
		padding: r(24px) r(32px)
		+bg(gray-50)