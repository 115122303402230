.form-group
	+all-text-inputs
		height: r(40px)
		width: 100%
		background: transparent
		border: 1px solid color(secondary-100)
		padding: 0 r(20px)
		+text(white)
		font-size: r(16px)
		+placeholder
			+text(secondary-100)
			opacity: 1
	select
		height: r(40px)
		width: 100%
		+bg(white)
		border: 1px solid color(secondary-100)
		padding: 0 r(20px)
		color: #333
		font-size: r(16px)
		+placeholder
			color: #333
	textarea
		height: r(142px)
		padding: r(12px) r(20px)

.frm-btnwrap
	margin-top: r(20px)

.frm-btn
	position: relative
	display: inline-block
	margin-left: auto
	min-width: r(115px)
	&:before
		+center(Y)
		right: r(30px)
		content: '\f178'
		font-family: "Font Awesome 6 Pro"
		+text(white)
		pointer-events: none
		font-weight: 300
	input[type="submit"]
		+trans-all
		+bg(white)
		+text(secondary-500)
		border: 0
		height: r(40px)
		width: 100%
		cursor: pointer
		font-size: r(14px)
		text-transform: uppercase
		font-weight: 500