.home-7
	.section-heading
		+mq-min(lg)
			padding-right: r(142px)
	
.document-slider-images
	.swiper-container
		padding: r(20px)
		margin: r(-20px)
	+mq-min(lg)
		padding-left: r(44px)

.document-slider-thumb
	+mq-min(lg)
		margin-right: r(44px)

.document-item
	.img
		border: 1px solid #E0E0E0
		border-radius: r(20px)
		figure
			+res-ratio(296,396)
			img
				object-fit: contain
				width: 90%
				height: auto
				+center(both)
				top: 50%
				left: 50%
	&.is-big
		.img
			figure
				+res-ratio(440,600)
				img
					object-fit: contain
					width: 90%
					height: auto
					+center(both)
					top: 50%
					left: 50%
	&.has-shadow
		.img
			box-shadow: 6px 6px r(20px) rgba(0, 0, 0, 0.1)

.swiper-slide-thumb-active
	.document-item
		.img
			border: 1px solid color(secondary-500)