.home-4
	background: #08080A
	+mq-min(lg)
		height: r(960px)
		position: relative
	.img
		figure
			+mq-min(lg)
				height: r(960px)
				img
					+fit-cover
	.content-container
		+mq-min(lg)
			display: flex
			flex-direction: column
			justify-content: center
			position: absolute
			+fill
	.article
		+mq-max(lg)
			padding: r(40px) 0
		+mq-min(lg)
			padding-left: r(112px)
	.article-content
		.scrollable-content
			+mq-min(lg)
				direction: rtl
				max-height: r(600px)
				overflow-y: auto
				padding-left: r(20px)
				+scrollbars(r(4px),color(primary-500),rgba(#fff , 0.1))
			p
				padding: r(12px) 0
				border-top: 1px solid rgba(#fff , 0.1)
				direction: ltr
				span
					display: block
					padding: r(8px) r(20px)
					+bg-opa(#fff, 0.1)
					&:nth-child(2)
						margin-top: r(12px)
					& + span
						border-top: 1px solid rgba(#fff , 0.1)