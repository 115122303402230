//Reset
@import src/_plugins/sass-libraries/reset.sass
@import src/_plugins/sass-libraries/util.scss
// @import src/_plugins/sass-libraries/fluid-type.sass
@import src/_plugins/sass-libraries/family.scss
@import src/_plugins/sass-libraries/columnizer.scss
@import src/_plugins/sass-libraries/svg-background.scss

//Gridflex
$gl-gridName: row
$gl-gutter: r(32px)
$gl-gutter-vertical: r(32px)
$gl-mq-width: 'min-width'

@import src/_plugins/gridlex/src/gridlex.scss

// Global
html
	font-size: 3.6vw
	+mq-min(md)
		font-size: 1.75vw
	+mq-min(lg)
		font-size: 1vw
body
	line-height: 1
	font-family: 'Be Vietnam Pro'
	color: #333
	+mq-min(xl)
		font-size: r(16px)


*,*:before,*:after
	box-sizing: border-box
	outline: none

a
	text-decoration: none
