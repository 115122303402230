.home-reason
	+mq-max(lg)
		background: color(primary-500) !important
	+mq-min(lg)
		height: r(840px)
	.row
		+mq-min(lg)
			margin-left: 0
			margin-bottom: 0
			> *
				padding-bottom: 0
				padding-left: 0
	.item-col
		+mq-min(lg)
			& + .item-col
				border-left: 1px solid color(white)