$threshold: 55%

@function contrast-color($color, $dark, $light)
	@return if(lightness($color) < $threshold, $light, $dark)
    
@each $color, $value in $button
	.btn-#{$color}
		background-color: $value
		color: contrast-color($value, #333, #fff)
		+on-hover
			background-color: darken($value, 10%)

	.btn-border-#{$color}
		border: 1px solid $value
		color: $value
		+on-hover
			background-color: $value
			color: contrast-color($value, #333, #fff)

// @each $gradient, $value in $gradients
// 	.btn-#{$gradient}
// 		background: $value

.btn
	padding: r(12px) r(16px)
	display: inline-flex
	+trans-all
	+font(400,16px,24px)
	+flex-center
	text-transform: uppercase
	min-width: r(168px)
	+content-margins
		display: inline-block
		margin-left: r(8px)
	span
		font-size: r(16px)

.swiper-btn
	display: inline-flex
	+flex-center
	+text(primary-500)
	font-size: r(48px)
	cursor: pointer
	z-index: 2
	transition: 0.3s all
	+remove(lg,max)
	position: absolute
	+center(Y)
	&-white
		border-color: #fff
		+text(white)
	+mq-min(lg)
		margin: 0 r(12px)
		&.swiper-prev
			right: 100%
		&.swiper-next
			left: 100%