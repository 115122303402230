.global-header
	+bg(white)
	position: relative
	+mq-max(lg)
		padding: r(8px) 0

.logo
	width: r(280px)
	a
		display: block
		+res-ratio(280,31)

.menu-wrap
	+mq-max(lg)
		position: absolute
		top: 100%
		padding: 1rem 0
		width: 100%
		left: 0
		+bg(primary-500)
		display: none
		z-index: 2
	
.menu
	+mq-min(lg)
		&.container
			padding: 0
			max-width: none
	> ul
		display: flex
		align-items: center
		+content-margins
			margin-left: r(40px)
		+mq-max(lg)
			display: block
			+content-margins
				margin-left: 0
				margin-top: r(16px)
		> li
			> a
				text-transform: uppercase
				font-weight: 500
				+text(gray-700)
				display: inline-flex
				align-items: center
				height: r(100px)
				position: relative
				+mq-max(lg)
					height: r(40px)
					+text(white)
				&::before
					+pseudo
					height: 2px
					+bg(secondary-500)
					bottom: 0
					width: 100%
					opacity: 0
					+mq-max(lg)
						+bg(white)
			&.active
				> a
					+text(secondary-500)
					&::before
						opacity: 1
					+mq-max(lg)
						+text(white)
			+on-hover
				> a
					+text(secondary-500)

.has-sub
	position: relative
	display: flex
	align-items: center
	> span
		margin-left: 5px
	.sub-menu
		display: none
		position: absolute
		top: 100%
		left: 0
		z-index: 2
		+mq-min(lg)
			min-width: r(240px)
		ul
			box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15)
			background: #fff
			li
				a
					white-space: nowrap
					display: flex
					align-items: center
					font-size: r(14px)
					padding: r(12px) r(24px)
					+trans-all
				&:hover,&.active
					a
						+bg(secondary-500)
						+text(white)
	+mq-max(lg)
		justify-content: space-between
		flex-wrap: wrap
		> span
			+text(white)
			position: relative
		.sub-menu
			position: static
			width: 100%
			border-top: 0
			box-shadow: none
			ul
				background: transparent
				background: #fff
				color: #333
				li
					a
						white-space: initial
					&.active
						a
							margin-right: 0
							border-radius: 0
	+mq-min(lg)
		.toggle
			display: none
		&:hover
			.sub-menu
				display: block

.header-util
	+content-margins
		margin-left: r(12px)

.language
	ul
		display: flex
		align-items: center
		padding: 0 r(8px)
		+font(400,12px,16px)
		li
			&.active
				font-weight: 700
		+content-margins
			margin-left: r(8px)
			border-left: 1px solid color(gray-200)
			padding-left: r(8px)
		+mq-max(lg)
			font-size: r(16px)
			padding: 0

.search-toggle
	display: flex
	+flex-center
	padding: r(12px)
	+text(primary-500)