.home-6
	+mq-max(lg)
		padding: r(40px) 0
	.content-wrap
		+mq-min(lg)
			padding: r(120px) 0
	.img
		mix-blend-mode: multiply

.accordion-list
	+content-margins
		margin-top: r(12px)

.accordion-item
	display: flex
	+bg(white)
	padding: r(20px)
	filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.08))
	+radius(r(8px))
	cursor: pointer
	.accordion-icon
		+flex-width(r(40px))
		span
			+circle(r(40px))
			+bg(white)
			+text(primary-500)
			+flex-center
			+trans-all
			display: flex
			font-size: r(24px)
			box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15)
	.accordion-content
		flex: 1
		padding-left: r(24px)
		.title
			+trans-all
		.content
			display: none
	&.active
		.accordion-icon
			span
				+bg(secondary-500)
				+text(white)
		.accordion-content
			.title
				+text(secondary-500)